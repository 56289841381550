*,
*::before,
*::after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

html {
    scroll-behavior: smooth;
}

@import "~react-image-gallery/styles/css/image-gallery.css";

/* Start Body */
body {
    margin: 0;
    font-size: 1rem;
    font-family: sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    scroll-behavior: smooth;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

img {
    width: 100%;
    max-width: 100%;
}

:root {
    --primaryDark: #2B2730;
    --darkGrey: #2B2730;
    --darkColor: #000000;
    --lightColor: #FFFFFF;
    --lightGrey: #5D5D5D;
    --greyColor: #616161;
    --boderColor: #DADADA;
}

.displaySection {
    float: left;
    width: 100%;
}

.container {
    width: 1170px;
    margin: 0 auto;
}

a {
    text-decoration: none;
}

.rowFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Sticky sidebar */


nav.stickyNav ul li {
    list-style: none;
    font-size: 15px;
    font-weight: 600;
}

/* Header */
header {
    background: var(--primaryDark);
    padding: 12px 0;
    width: 100%;
}

.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

header nav ul li a {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-transform: capitalize;
    color: var(--lightColor);
    text-decoration: none;
    padding: 8px 18px;
    position: relative;
    cursor: pointer;
}

header nav ul li.active a span {
    opacity: 1;
}

header nav ul li a span {
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    border-radius: 100%;
    left: 0;
    right: 0;
    border: 0;
    margin: 0 auto;
    opacity: 0;
}

header nav ul li a:hover span {
    opacity: 1;
}

header nav ul li:last-child a {
    padding-right: 0;
}

header nav ul li {
    display: inline-block;
}

/* Header End */

/* Banner */
.heroBanner {
    padding: 130px 0 50px;
}

.heroBanner .leftText h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 69px;
    line-height: 100px;
    text-transform: capitalize;
}

.heroBanner .leftText p {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    text-transform: capitalize;
    color: var(--darkColor);
}

.heroBanner .leftText a {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    text-transform: capitalize;
    color: var(--lightColor);
    border-radius: 40px;
    padding: 10px 30px;
    margin-top: 35px;
    display: inline-block;
}

.heroBanner .leftText a:hover {
    background: var(--darkColor);
}

.heroBanner .leftText {
    max-width: 35rem;
}

.heroBanner .leftImg {
    max-width: 30rem;
}

/* Gallery Slider */

.gallerSlider {
    padding: 50px 0;
}

.gallerSlide h3,
.generalFlex .leftBox .headTitle h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 75px;
    text-transform: capitalize;
    color: var(--primaryColor);
    margin-bottom: 34px;
    margin-left: 0px;
}

.owl-carousel.owl-drag .owl-item .last {
    display: none;
}

.gallerSlider .owl-theme .owl-nav {
    margin-top: 10px;
    position: absolute;
    top: -92px;
    right: 38px;
}

.owl-carousel.owl-drag .owl-item .item img {
    border-radius: 7px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gallerSlider .owl-theme .owl-nav button, .gallerSlider .owl-theme .owl-nav button:hover {
    background: transparent;
}

button.owl-prev {
    right: 19px;
    position: relative;
}

.owl-theme .owl-nav .disabled {
    filter: contrast(0) brightness(0);
}

/* Promo */
.headTitle h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
}

.headTitle h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 37px;
    line-height: 43px;
    text-align: center;
    color: var(--darkColor);
}

.commonStyleBox {
    border: 1px solid #DADADA;
    border-radius: 10px;
    padding: 28px 0px 28px 28px;
    width: 96%;
}

.addressInfo.commBox1 {
    text-align: center;
    padding-right: 19px;
}

.commonStyleBox h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
}

.commonStyleBox p {
    font-style: italic;
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;
    color: var(--darkColor);
}

.commonStyleBox a {
    background: var(--primaryColor);
    border-radius: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
    color: var(--lightColor);
    padding: 10px 30px;
    display: inline-block;
    margin-top: 5px;
}

.commonStyleBox a:hover {
    background: var(--darkColor);
}

.addressInfo h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-transform: capitalize;
    color: var(--lightGrey);
}

.addressInfo.commBox1 h6.space {
    margin-top: 16px;
}

.addressInfo.commBox1 h6 {
    line-height: 11px;
}

.addressInfo h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-transform: capitalize;
}

.commonStyleBox .commBox {
    width: 61%;
    border-right: 2px dashed #BBBBBB;
    padding-right: 20px;
}

.commonStyleBox {
    position: relative;
}

.colrowWrap {
    margin-top: 50px;
}

.commonStyleBox:after {
    position: absolute;
    content: "";
    width: 2px;
    right: 0;
    top: 18px;
    height: 84%;
    border-radius: 38px;
    vertical-align: middle;
}

/* General Section */
.generalSection .rowFlex {
    flex-wrap: wrap;
}

.generalFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.generalFlex .leftBox, .generalFlex .rightBox {
    width: 50%;
}

.generalFlex .leftBox {
    margin-top: 0;
    padding-right: 45px;
    text-align: center;
}

.generalFlex .leftBox .headTitle p {
    font-size: 18px;
    line-height: 33px;
    padding-right: 0;
    margin-top: 25px;
}

.generalFlex .leftBox .headTitle h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 49px;
    text-transform: capitalize;
    margin-bottom: 20px;
    padding-right: 0;
}

.generalFlex .rightBox img {
    object-fit: contain;
}

.generalFlex .leftBox {
    padding-left: 45px;
    text-align: right;
}

.generalFlex:nth-child(even) .leftBox {
    order: 2;
    text-align: left;
}

.generalFlex:not(:first-child) {
    padding-top: 100px;
}

/* End of General Section */

/* Review */
#reviewSlider .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.review .container {
    display: flex;
}

.review .leftreviewText {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.leftreviewText h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
}

.leftreviewText h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 37px;
    line-height: 43px;
    text-transform: capitalize;
    color: var(--darkColor);
    padding-right: 5%;
}

.leftreviewText p {
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-transform: capitalize;
    font-feature-settings: 'cpsp' on;
    color: var(--darkColor);
    padding-right: 25%;
    margin-top: 25px;
}

.leftreviewText span {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 35px;
    color: #000000;
    margin-top: 36px;
    display: block;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: transparent !important;
    color: #FFF;
    text-decoration: none;
}

#reviewSlider .owl-nav {
    position: absolute;
    bottom: -100px;
    left: 58%;
}

.review {
    padding: 50px 0;
    margin-top: 50px;
}

/* Contact */
.address h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    text-transform: capitalize;
    color: var(--darkColor);
}

.address p {
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 29px;
    color: var(--darkColor);
}

.calling {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 33px;
    margin-top: 23px;
}

.iconCallMobile h4 {
    display: none;
}

.calling .iconCall {
    border-radius: 100%;
    width: 76px;
    height: 76px;
    padding: 23px 27px;
    margin-right: 13px;
}

.numberCall h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
}

.numberCall a {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 14px;
    color: var(--greyColor);
}

address {
    border: 1px solid #DADADA;
    border-radius: 10px;
    padding: 23px 27px;
    margin-bottom: 0px;
}

address h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
}

address ul li {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    text-align: left;
}

address ul {
    padding: 0;
    margin: 0;
}

.address address p {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 37px;
    color: var(--greyColor);
    white-space: pre-wrap;
    margin-bottom: 0;
}

.contactSection .rowFlex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.contactSection {
    padding-top: 50px;
    padding-bottom: 50px;
}

.contactSection .map {
    width: 74%;
    padding-left: 2%;
}

/* Footer */
footer {
    background: var(--primaryDark);
    padding: 20px 0;
}

.footerBrand img {
    width: 147.59px;
}

.fooColumn {
    width: 33.33%;
}

.socialLink {
    display: flex;
    color: var(--lightColor);
    justify-content: center;
    align-items: center;
}

.copyWrite {
    color: var(--lightColor);
    text-align: right;
}

.copyWrite p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: var(--lightColor);
    margin-bottom: 0;
}

.copyWrite p a {
    text-decoration: none;
}

.socialLink span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    margin-right: 5px;
}

.socialLink a {
    margin: 0 7px;
    padding: 0;
    line-height: 0px;
}


/* Mobile Menu */
.mobileMenu {
    display: none;
    cursor: pointer;
}

div#sidebarMenu ul li a {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-transform: capitalize;
    color: var(--lightColor);
    text-decoration: none;
    padding: 8px 20px;
    position: relative;
}

div#sidebarMenu ul li a.active:before {
    opacity: 1;
}

div#sidebarMenu ul li a::before {
    position: absolute;
    background: var(--primaryColor);
    width: 7px;
    height: 7px;
    border-radius: 100%;
    content: "";
    left: 0;
    margin: 0 auto;
    bottom: 17px;
    opacity: 0;
}

div#sidebarMenu ul li:hover a:before {
    opacity: 1;
}

div#sidebarMenu ul {
    padding: 0;
    margin-left: 19px;
}

div#sidebarMenu ul li a:hover {
    color: var(--primaryColor);
}

div#sidebarMenu ul li {
    padding-bottom: 16px;
}

.main {
    margin: 0 auto;
    display: block;
    height: 100%;
    margin-top: 60px;
}

.mainInner {
    display: table;
    height: 100%;
    width: 100%;
    text-align: center;
}

.mainInner div {
    display: table-cell;
    vertical-align: middle;
    font-size: 3em;
    font-weight: bold;
    letter-spacing: 1.25px;
}

#sidebarMenu {
    height: 100vh;
    position: fixed;
    left: 0;
    width: 250px;
    margin-top: 0;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;
    background: var(--primaryDark);
    z-index: 999;
    top: 64px;
}

.sidebarMenuInner {
    margin: 0;
    padding: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.sidebarMenuInner li {
    list-style: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.sidebarMenuInner li span {
    display: block;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.50);
}

.sidebarMenuInner li a {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

input[type="checkbox"]:checked ~ #sidebarMenu {
    transform: translateX(0);
}

input[type=checkbox] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
}

.sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: fixed;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 22px;
    left: 15px;
    height: 22px;
    width: 22px;
    z-index: 99999999;
}

.spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 2px;
    width: 90%;
    background-color: var(--primaryColor);
    border-radius: 4px;
    margin: 2px 0;
}

.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

.diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
}

.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
}

input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}

input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -11px;
}


/* Media */
@media screen and (max-width: 1920px) and (min-width: 1600px) {
    header nav ul li a {
        font-size: 20px;
        padding: 8px 20px;
    }

    .heroBanner {
        padding: 130px 0 50px;
    }

    .gallerSlide h3,
    .generalFlex .leftBox .headTitle h3 {
        font-size: 64px;
    }

    .headTitle h3 {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: .4em;
    }

    .headTitle h2 {
        font-size: 62px;
        line-height: 75px;
    }

    .colrowWrap {
        margin-top: 59px;
    }

    .generalFlex .leftBox .headTitle p {
        font-size: 30px;
    }

    .commonStyleBox h4 {
        font-size: 26px;
        line-height: 30.47px;
    }

    .commonStyleBox p {
        font-size: 20px;
        line-height: 23.44px;
    }

    .commonStyleBox a {
        font-size: 18px;
        line-height: 26px;
        padding: 22px 85px;
        margin-top: 18px;
    }

    .container {
        width: 90%;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .commonStyleBox .commBox {
        width: 59%;
        padding-right: 20px;
    }

    .addressInfo.commBox1 {
        text-align: center;
        padding-right: 0px;
        width: 43%;
    }

    .addressInfo h6 {
        font-size: 20px;
        line-height: 23px;
    }

    .addressInfo.commBox1 h6.space {
        margin-top: 19px;
    }

    .colrowWrap {
        margin-top: 50px;
        padding: 0 18px;
    }

    .review {
        margin-top: 50px;
        padding-bottom: 0;
    }

    .leftreviewText h5 {
        font-size: 64px;
        line-height: 42px;
        margin-bottom: 22px;
        font-weight: 600;
    }

    .leftreviewText h4 {
        font-size: 40px;
        line-height: 75px;
        padding-right: 35%;
    }

    .leftreviewText p {
        font-size: 28px;
        line-height: 33px;
        padding-right: 23%;
        margin-top: 25px;
    }

    .leftreviewText span {
        font-size: 30px;
        line-height: 35px;
        margin-top: 36px;
    }

    .owl-item {
        opacity: 0;
        transition: opacity 50ms;
    }

    .owl-item.active {
        opacity: 1;
        transition: opacity 50ms;
    }

    .imageReview {
        width: 94%;
    }

    .numberCall a {
        font-size: 24px;
        line-height: 28px;
    }

    .calling .iconCall {
        width: 96px;
        height: 96px;
    }

    .numberCall h4 {
        font-size: 35px;
        line-height: 41px;
        margin-bottom: 16px;
    }

    address ul li span {
        font-size: 24px;
        line-height: 45px;
    }

    .contactSection .rowFlex {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .heroBanner .leftImg {
        max-width: 47rem;
    }

    .heroBanner .leftText a {
        font-size: 22px;
        line-height: 26px;
        padding: 22px 67px;
        margin-top: 35px;

    }

    address h3 {
        font-size: 35px;
        line-height: 41px;
        margin-bottom: 27px;
    }

    .calling {
        margin-bottom: 47px;
        margin-top: 39px;
    }

    address {
        padding: 54px 50px;
        margin-bottom: 0px;
    }

    .address h2 {
        font-size: 64px;
        line-height: 75px;
        font-weight: 600;
    }

    .address p {
        font-size: 30px;
        line-height: 35.16px;
        padding-right: 106px;
    }

    .contactSection .map {
        width: 60%;
        padding-left: 0%;
    }

    .contactSection .address {
        width: 37%;
    }
}


@media only screen and (max-width: 1200px) {
    .container {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .header .rowFlex {
        justify-content: center;
    }

    .mobileMenu {
        display: block !important;
    }
}

@media only screen and (max-width: 1024px) {
    .header nav {
        display: none;
    }

    .heroBanner .leftText h1 {
        font-size: 49px;
        line-height: 74px;
    }

    .addressInfo.commBox1 {
        text-align: center;
        padding-right: 7px;
    }

    .heroBanner .leftText p {
        font-size: 26px;
        line-height: 34px;
    }

    .heroBanner .leftText a {
        margin-top: 19px;
    }

    .gallerSlide h3 {
        font-size: 41px;
        line-height: 75px;
        margin-bottom: 34px;
        margin-left: 63px;
    }

    .gallerSlider .owl-theme .owl-nav {
        top: -89px;
    }

    #generalSection {
        margin: 0;
        padding: 0;
    }

    .generalFlex:not(:first-child) {
        padding-top: 65px;
    }

    .generalFlex .leftBox .headTitle p {
        font-size: 17px;
        line-height: 30px;
    }

    .new-templte-revivew .head h5, .generalFlex .leftBox .headTitle h3 {
        font-size: 45px;
        line-height: 54px;
    }

    .generalFlex .leftBox {
        padding-right: 39px;
    }

    .headTitle h2 {
        font-size: 41px;
        line-height: 43px;
    }

    .commonStyleBox p {
        font-size: 15px;
        line-height: 20px;
    }

    .addressInfo h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .commonStyleBox a {
        font-size: 13px;
        line-height: 26px;
        padding: 6px 22px;
        margin-top: 5px;
    }

    .leftreviewText h4 {
        font-size: 37px;
        line-height: 46px;
        padding-right: 5%;
    }

    .leftreviewText p {
        font-size: 20px;
        line-height: 25px;
        padding-right: 17%;
        margin-top: 9px;
    }

    .leftreviewText span {
        margin-top: 20px;
    }

    .review {
        padding: 31px 0;
    }

    .address h2 {
        font-size: 41px;
        line-height: 45px;
    }

    .address p {
        font-size: 24px;
        line-height: 26px;
    }

    .calling .iconCall {
        width: 46px;
        height: 46px;
        padding: 9px 12px;
        margin-right: 13px;
    }

    .numberCall {
        text-align: left;
    }

    .numberCall h4 {
        font-size: 22px;
        line-height: 27px;
    }

    .numberCall a {
        font-size: 20px;
        line-height: 17px;
    }

    .copyWrite p {
        margin-bottom: 0;
    }

    .leftreviewText h5 {
        font-size: 27px;
        line-height: 34px;
    }
}

@media only screen and (max-width: 820px) {
    .generalFlex .leftBox,
    .generalFlex:nth-child(even) .leftBox {
        padding-left: 0;
        text-align: center;
    }

    .new-templte-revivew .head h5, .generalFlex .leftBox .headTitle h3 {
        font-size: 41px;
        line-height: 48px;
        font-weight: 600;
    }

    .generalFlex .leftBox .headTitle h3 {
        margin-bottom: 0;
    }

    .generalFlex {
        display: block;
        text-align: center;
    }

    .generalFlex:not(:first-child) {
        padding-top: 65px;
    }

    .generalFlex .leftBox .headTitle h3 {
        padding-right: 0;
        text-align: center;
    }

    .generalFlex .leftBox, .generalFlex .rightBox {
        width: 100%;
    }

    .generalFlex .leftBox {
        padding-right: 0;
    }

    #generalSection {
        margin: 0;
        padding: 0;
    }

    .generalFlex .leftBox .headTitle {
        margin-bottom: 37px;
        padding: 0 101px;
    }

    .generalFlex .leftBox .headTitle p {
        font-size: 24px;
        line-height: 32px;
    }

    .review .container {
        display: block;
    }

    .review .leftreviewText {
        width: 100%;
    }

    .socialLink {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        right: 17px;
        top: 23px;
    }

    .contactSection .rowFlex {
        display: block;
        align-items: flex-end;
        justify-content: space-between;
    }

    footer {
        margin-top: 0 !important;
    }

    address ul li span:last-child {
        text-align: right;
    }

    div#reviews {
        margin-top: 50px;
    }

    .contactSection {
        padding-bottom: 50px;
        margin-top: 50px !important;
    }

    .address address p {
        font-size: 24px;
    }

    .gallerSlider .owl-theme .owl-nav {
        top: -69px;
    }

    .flexCenterBut {
        text-align: center;
    }

    .rowFlex {
        display: block;
    }

    .brand a img {
        height: 32px;
        width: auto;
    }

    .addressInfo h3 {
        font-size: 25px;
        line-height: 34px;
    }

    #contact-us .rowFlex {
        display: block;
    }

    .heroBanner .leftText {
        max-width: initial;
        text-align: center;
    }

    .fooColumn {
        width: 100%;
    }

    .copyWrite p {
        text-align: left;
    }

    .copyWrite {
        text-align: left;
    }

    .heroBanner .rowFlex {
        display: flex;
        flex-direction: column-reverse;
    }

    .heroBanner {
        padding: 0 0 56px;
    }

    .heroBanner .leftText p {
        font-size: 26px;
        line-height: 34px;
        max-width: 18em;
    }

    .gallerSlider.displaySection {
        padding: 50px 0;
        margin-top: 0;
    }

    .gallerSlide h3 {
        font-size: 41px;
        line-height: 75px;
        margin-bottom: 13px;
        margin-left: 0;
        text-align: center;
    }

    .headTitle h3 {
        font-size: 29px;
        line-height: 27px;
    }

    .headTitle h2 {
        font-size: 28px;
        line-height: 32px;
    }

    .commonStyleBox {
        width: 100%;
        margin-bottom: 15px;
    }

    .commonStyleBox .commBox {
        width: 50%;
    }

    .addressInfo.commBox1 {
        text-align: center;
        padding-right: 19px;
        width: 50%;
    }

    .review {
        margin-top: 46px;
        padding: 50px 0 0;
    }

    .leftreviewText h5 {
        font-size: 41px;
        line-height: 41px;
        text-align: center;
        font-weight: 600;
    }

    .leftreviewText h4 {
        font-size: 28px;
        line-height: 38px;
        padding-right: 5%;
        text-align: center;
    }

    .leftreviewText p {
        font-size: 22px;
        line-height: 23px;
        padding-right: 0px;
        max-width: 79%;
        margin: 7px auto;
    }

    .contactSection .address {
        text-align: center;
    }

    .calling {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 33px;
        margin-top: 23px;
    }

    address {
        padding: 23px 27px;
        margin-bottom: 23px;
        width: 87%;
        margin: 0 auto;
    }

    .contactSection .map {
        width: 100%;
        padding-left: 0;
        margin-top: 43px;
    }

    .displaySection {
        float: left;
        width: 100%;
        position: relative;
        margin-top: 97px;
    }

    header {
        background: var(--primaryDark);
        padding: 12px 0;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 99999;
    }

    #reviewSlider .item {
        display: block;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    header .brand {
        margin-top: 4px;
        text-align: center;
    }

    #reviewSlider .owl-nav {
        bottom: 0;
        left: 40%;
    }

}


/* Mobile query */
@media only screen and (max-width: 600px) {
    .heroBanner .leftText h1 {
        font-size: 24px;
        line-height: 28px;
    }

    .addressInfo h3 {
        font-size: 19px;
        line-height: 31px;
    }

    .addressInfo.commBox1 {
        padding-right: 0;
    }

    .generalFlex .leftBox {
        padding-right: 0;
    }

    #generalSection {
        margin: 0;
        padding: 50px 0;
    }

    .generalFlex .leftBox .headTitle {
        margin-bottom: 37px;
        padding: 0 0px;
    }

    div#reviews {
        margin-top: 40px;
    }

    div#contact-us {
        margin-top: 17px !important;
    }

    footer {
        margin-top: 22px !important;
    }

    .heroBanner .leftText p {
        font-size: 20px;
        line-height: 23px;
        max-width: 13em;
    }

    #reviewSlider .owl-nav {
        bottom: 15px;
        position: relative;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .contactSection {
        padding-bottom: 0;
    }

    .owl-carousel .owl-item img {
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }

    .contactSection {
        margin-top: 50px !important;
    }

    .heroBanner {
        padding: 21px 0 0;
    }

    .leftreviewText p {
        max-width: 100%;
        font-size: 13.3846px;
        line-height: 16px;
    }

    .review {
        padding-top: 57px;
    }

    .gallerSlider {
        padding: 50px 0 0;
    }

    .displaySection {
        float: left;
        width: 100%;
        position: relative;
        margin-top: 50px;
    }

    .gallerSlider .owl-theme .owl-nav {
        margin-top: 12px;
        position: relative;
        top: -119px;
        right: 0;
        left: 9px;
        top: inherit;
    }

    .gallerSlide h3,
    .generalFlex .leftBox .headTitle h3 {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 27px;
        margin-left: 0;
        text-align: center;
        color: var(--primaryColor);
        position: relative;
        padding-bottom: 15px;
        font-weight: 600;
    }


    .gallerSlide h3::before,
    .generalFlex .leftBox .headTitle h3::before {
        position: absolute;
        content: "";
        background: var(--primaryColor);
        width: 50px;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .headTitle h3 {
        font-size: 24px;
        line-height: 28px;
        padding-bottom: 15px;
        position: relative;
        margin-bottom: 13px;
    }

    .review {
        margin-top: 45px;
        padding-top: 44px;
    }

    .addressInfo.commBox1 h6.space {
        margin-top: 5px;
    }

    .headTitle h3::before {
        position: absolute;
        content: "";
        background: var(--primaryColor);
        width: 50px;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .headTitle h2 {
        font-size: 20px;
        line-height: 24px;
    }

    .headTitle h2 br {
        display: none;
    }

    .commonStyleBox h4 {
        font-size: 12px;
        line-height: 15px;
    }

    .commonStyleBox p {
        font-size: 11px;
        line-height: 13px;
    }

    .commonStyleBox {
        padding: 25px 0px 25px 12px;
    }

    .addressInfo h6 {
        font-size: 12px;
        line-height: 14px !important;
    }

    .commonStyleBox a {
        font-size: 12px;
        line-height: 14px;
        padding: 6px 16px;
        margin-top: 5px;
    }

    .review .container {
        flex-direction: column;
    }

    .review .leftreviewText {
        width: 100%;
    }
    .leftreviewText h5 {
        font-size: 24px;
        line-height: 18px;
        position: relative;
        padding-bottom: 15px;
        text-align: center;
    }

    .leftreviewText h5::before {
        position: absolute;
        content: "";
        background: var(--primaryColor);
        width: 50px;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .leftreviewText h4 {
        font-size: 22px;
        line-height: 26px;
        padding-right: 0;
        margin: 20px auto;
    }

    .leftreviewText .owl-nav button {
        width: 24px;
    }

    address {
        padding: 23px 27px;
        margin-bottom: 23px;
        width: 100%;
        margin: 0 auto;
        text-align: left;
    }

    .address h2 {
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
    }

    .address p {
        font-size: 15px;
        line-height: 18px;
    }

    address h3 {
        font-size: 20px;
        line-height: 23px;
    }

    address ul li span {
        font-size: 14px;
        line-height: 30px;
    }

    .socialLink span {
        display: none;
    }

    .copyWrite p {
        font-size: 10px;
    }

    .iconCallMobile h4 {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: var(--primaryColor);
        margin-bottom: 0;
    }

    .numberCall a {
        font-size: 18px;
        line-height: 17px;
    }

    .calling .iconCall {
        width: 25px;
        height: 25px;
        padding: 2px 7px;
    }

    .owl-carousel.owl-drag .owl-item .last {
        display: block;
    }

    .numberCall h4 {
        display: none;
    }

    .calling {
        display: block;
        align-items: center;
        justify-content: center;
        margin-bottom: 33px;
        margin-top: 23px;
    }

    .numberCall {
        text-align: center;
    }

    .numberCall a {
        margin-top: 15px;
        display: block;
    }

    .iconCallMobile {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


.ReactModalPortal {
    z-index: 9999999;
    position: relative;
}

#gallerySlider button {
    border: 0;
    box-shadow: none;
    padding: 0;
    z-index: 99999999999999;
    position: relative;
    cursor: pointer;
}

button.close__model {
    position: absolute;
    top: 7px;
    right: 8px;
    background: none;
    border: 0;
    font-size: 25px;
    cursor: pointer;
}

.image-gallery-slides {
    height: 400px;
    object-fit: cover;
}


.stickyNav ul li {
    padding: 9px 0;
}

nav.stickyNav ul li a {
    font-size: 17px;
    color: #2b2730;
}

.templateToggle {
    position: fixed;
    right: 19px;
    top: 36%;
}

.sidebar {
    width: 280px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 999999999999999;
    transition: 0.5s;
}

.sd-header .btn {
    cursor: pointer;
}

.templateToggle i {
    font-size: 28px;
    color: #DC4238;
    cursor: pointer;
}

.sidebar.active {
    right: 0;
}

.sd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}

.mainTemp:hover {
    color: #DC4238;
}

.sushiTemp:hover {
    color: #FFAA00;
}

.itaTemp:hover {
    color: #A020F0;
}

.chiTemp:hover {
    color: #DC4238;
}

.pizzaTemp:hover {
    color: #1F9D1F;
}

.pastaTemp:hover {
    color: #CB7847;
}

.seaTemp:hover {
    color: #E75E03;
}

.burTemp:hover {
    color: #0000FF;
}

.mexTemp:hover {
    color: #FFD809;
}

.griTemp:hover {
    color: #00C2FF;
}

.ramTemp:hover {
    color: #a301ef;
}

.hide {
    display: none !important;
}

/* Nav active color default settings */
:root {
    --primaryColor: white;
}

.v1_head nav ul li a.active {
    color: var(--primaryColor);
}

.v1__promo .commonStyleBox:after {
    background: var(--primaryColor);
}

.v1_head nav ul li a.active span {
    opacity: 1;
}
